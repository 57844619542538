import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';

const FitSizeGuide: React.FC = () => {
  const { formatMessage } = useFormat({ name: 'Guides' });
  return (
    <div className="my-32 grid grid-cols-1 gap-52 lg:grid-cols-8">
      <div className="col-span-2">
        <Typography fontFamily="NunitoSansBold" className="tpb-5 text-28">
          Fit Guide
        </Typography>

        <ul className="sticky top-[145px] font-NunitoSansSemiBold text-16">
          <li className="border-b py-10">
            <a href="#SS-Crew-Tee">Short Sleeve Crew Tee</a>
          </li>
          <li className="border-b py-10">
            <a href="#LS-Tee">Long Sleeve Tee</a>
          </li>
          <li className="border-b py-10">
            <a href="#Mens-Polo">Men&apos;s Polo</a>
          </li>
          <li className="border-b py-10">
            <a href="#Womens-Polo">Women&apos;s Polo</a>
          </li>
          <li className="border-b py-10">
            <a href="#Mens-Facilities-Polo">Men&apos;s Facilities Polo</a>
          </li>
          <li className="border-b py-10">
            <a href="#Mens-Field-Leader-Polo">Men&apos;s Field Leader Polo</a>
          </li>
          <li className="border-b py-10">
            <a href="#Womens-Field-Leader-Polo">Women&apos;s Field Leader Polo</a>
          </li>
          <li className="border-b py-10">
            <a href="#Maternity-Polo">Maternity Polo</a>
          </li>
          <li className="border-b py-10">
            <a href="#Maternity-Tees">Maternity Tees</a>
          </li>
          <li className="py-10">
            <a href="#howToMeassure">How to measure</a>
          </li>
        </ul>
      </div>
      <div className="col-span-6">
        <Typography fontFamily="NunitoSansBold" className="pb-5 text-28" id="sizeGuides">
          Size Guide
        </Typography>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="SS-Crew-Tee">
          Short Sleeve Crew Tee (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 1/2</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="LS-Tee">
          Long Sleeve Tee (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39 3/4</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Mens-Polo">
          Men&apos;s Polo (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Short Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Long Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39 3/4</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Womens-Polo">
          Women&apos;s Polo (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 1/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Mens-Facilities-Polo">
          Men&apos;s Facilities Polo (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 7/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37</td>
              <td className="px-6 py-4 font-NunitoSansRegular">40</td>
              <td className="px-6 py-4 font-NunitoSansRegular">43</td>
              <td className="px-6 py-4 font-NunitoSansRegular">46</td>
              <td className="px-6 py-4 font-NunitoSansRegular">50</td>
              <td className="px-6 py-4 font-NunitoSansRegular">53</td>
              <td className="px-6 py-4 font-NunitoSansRegular">56</td>
              <td className="px-6 py-4 font-NunitoSansRegular">59</td>
              <td className="px-6 py-4 font-NunitoSansRegular">62</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 7/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 7/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">11 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Short Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Long Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">37 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">38 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39</td>
              <td className="px-6 py-4 font-NunitoSansRegular">39 3/4</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Mens-Field-Leader-Polo">
          Men&apos;s Field Leader Polo (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 7/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 1/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 3/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 7/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">11 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">12 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Short Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 5/8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/2</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Womens-Field-Leader-Polo">
          Women&apos;s Field Leader Polo (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">5XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">6XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34 1/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33 3/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">10 1/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/4</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Maternity-Polo">
          Maternity Polo (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 3/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Waist Width</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9 1/2</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Short Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Long Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">30 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">31 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32</td>
              <td className="px-6 py-4 font-NunitoSansRegular">32 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">33 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">34 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35</td>
              <td className="px-6 py-4 font-NunitoSansRegular">35 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">36 1/2</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="Maternity-Tees">
          Maternity Tees (Inches)
        </Typography>

        <table className="mb-32 h-16 w-full table-auto divide-y divide-gray-200 border">
          <thead className="bg-gray-200 text-left">
            <tr>
              <th className="px-6 py-4 font-NunitoSansRegular"></th>
              <th className="px-6 py-4 font-NunitoSansRegular">XXS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XS</th>
              <th className="px-6 py-4 font-NunitoSansRegular">S</th>
              <th className="px-6 py-4 font-NunitoSansRegular">M</th>
              <th className="px-6 py-4 font-NunitoSansRegular">L</th>
              <th className="px-6 py-4 font-NunitoSansRegular">XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">2XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">3XL</th>
              <th className="px-6 py-4 font-NunitoSansRegular">4XL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
              <td className="px-6 py-4 font-NunitoSansRegular">29</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Across Shoulder</td>
              <td className="px-6 py-4 font-NunitoSansRegular">13 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 3/4</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Chest</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23 1/3</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Front Waist Width</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Back Waist Width</td>
              <td className="px-6 py-4 font-NunitoSansRegular">14</td>
              <td className="px-6 py-4 font-NunitoSansRegular">15</td>
              <td className="px-6 py-4 font-NunitoSansRegular">16</td>
              <td className="px-6 py-4 font-NunitoSansRegular">17</td>
              <td className="px-6 py-4 font-NunitoSansRegular">18</td>
              <td className="px-6 py-4 font-NunitoSansRegular">19</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bottom Opening</td>
              <td className="px-6 py-4 font-NunitoSansRegular">20</td>
              <td className="px-6 py-4 font-NunitoSansRegular">21</td>
              <td className="px-6 py-4 font-NunitoSansRegular">22</td>
              <td className="px-6 py-4 font-NunitoSansRegular">23</td>
              <td className="px-6 py-4 font-NunitoSansRegular">24</td>
              <td className="px-6 py-4 font-NunitoSansRegular">25</td>
              <td className="px-6 py-4 font-NunitoSansRegular">26</td>
              <td className="px-6 py-4 font-NunitoSansRegular">27</td>
              <td className="px-6 py-4 font-NunitoSansRegular">28</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Bicep</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5</td>
              <td className="px-6 py-4 font-NunitoSansRegular">5 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">9</td>
            </tr>
            <tr>
              <td className="px-6 py-4 font-NunitoSansRegular">Short Sleeve Length</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">6 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 1/2</td>
              <td className="px-6 py-4 font-NunitoSansRegular">7 3/4</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8</td>
              <td className="px-6 py-4 font-NunitoSansRegular">8 1/2</td>
            </tr>
          </tbody>
        </table>

        <Typography fontFamily="NunitoSansBold" className="py-5 text-20" id="howToMeassure">
          How to measure
        </Typography>

        <Typography fontFamily="NunitoSansSemiBold" className="py-5 text-16">
          For accuracy have someone else take your measurements
        </Typography>

        <div className="my-10 flex gap-8">
          <Typography fontFamily="NunitoSansBold" className="text-16">
            Chest:
          </Typography>
          <Typography fontFamily="NunitoSansSemiBold" className="text-16">
            Raise your arms slightly and measure around the fullest part of your chest.
          </Typography>
        </div>

        <div className="my-10 flex gap-8">
          <Typography fontFamily="NunitoSansBold" className="text-16">
            Waist:
          </Typography>
          <Typography fontFamily="NunitoSansSemiBold" className="text-16">
            Measure is taken around the natural waistline. Ensure to keep the tape comfortably loose.
          </Typography>
        </div>

        <div className="my-10 flex gap-8">
          <Typography fontFamily="NunitoSansBold" className="text-16">
            Hip:
          </Typography>
          <Typography fontFamily="NunitoSansSemiBold" className="text-16">
            Stand with heels together. Keep the tape straight and parallel to the floor and measure around the fullest
            part.
          </Typography>
        </div>
        <div className="my-10 flex gap-8">
          <Typography fontFamily="NunitoSansBold" className="text-16">
            Bicep:
          </Typography>
          <Typography fontFamily="NunitoSansSemiBold" className="text-16">
            Wrap a tape measure around the fullest part of your upper arm.
          </Typography>
        </div>

        <div className="my-10 flex gap-8">
          <Typography fontFamily="NunitoSansBold" className="text-16">
            Arm length:
          </Typography>
          <Typography fontFamily="NunitoSansSemiBold" className="text-16">
            With your arm bent at 90 degrees. Place your hand on your hip. Measure from the back of your neck to your
            shoulder, elbow, and wrist.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FitSizeGuide;

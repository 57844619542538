import React from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';

const About: React.FC = () => {
  return (
    <div className="my-32 grid grid-cols-1 gap-52 lg:grid-cols-8">
      <div id="about-menu" className="col-span-8 lg:col-span-2">
        <ul id="about-menu-list" className="sticky top-[150px] font-NunitoSansSemiBold text-16">
          <li className="active border-b py-10">
            <a href="#philosophy">About The Site and Our Products</a>
          </li>
          <li className="border-b py-10">
            <a href="#partner">Featured Partner</a>
          </li>
        </ul>
      </div>
      <div className="col-span-8 lg:col-span-6">
        <div className="flex flex-wrap" id="philosophy">
          <div>
            <img
              className="mx-auto"
              src="/images/about-page_intro.jpg"
              title="About page_intro.jpg"
              alt="About page_intro.jpg"
            />
          </div>
          <div className="my-40 w-full">
            <div className="flex flex-col gap-32 lg:flex-row">
              <div className="w-full lg:w-3/4">
                <article>
                  <Typography className="mb-24" fontSize={36}>
                    About The Site and Our Products
                  </Typography>
                  <p className="mb-24 text-left font-semibold xl:text-justify">
                    This site is the official place for Chipotle employees to order Chipotle-branded clothing,
                    accessories and other items.&nbsp;
                  </p>
                  <p className="mb-24 text-left font-semibold xl:text-justify">
                    Only restaurant-based employees and their leadership have access to order uniforms. In support, here
                    is a link to our Uniform Policies and Style Guide:&nbsp;
                    <a
                      href="https://chipotlemexicangrill.sharepoint.com/sites/CodeOfEthics/Code%20of%20Ethics/Forms/AllItems.aspx?FolderCTID=0x012000230EE78E269E3B4BBF97AA34A0CD9701&id=%2Fsites%2FCodeOfEthics%2FCode%20of%20Ethics%2FChipLinks%20Policies%2FRestaurants%2FUniform%20Policies"
                      target="_blank"
                      rel="noreferrer"
                      className="underline underline-offset-4"
                    >
                      Uniform Policies and Style Guide.
                    </a>
                    &nbsp;We&apos;re proud that every shirt, polo and hat we provide to our restaurant team members is
                    made from organic and sustainably-sourced materials, which supports our purpose to Cultivate a
                    Better World!
                  </p>
                  <p className="text-left xl:text-justify">
                    Questions? Contact:&nbsp;
                    <a href="mailto:chipotle.support@loomstate.org" className="underline underline-offset-4">
                      chipotle.support@loomstate.org
                    </a>
                  </p>
                </article>
              </div>
              <div className="w-full self-center lg:w-1/4">
                <img
                  className="mx-auto"
                  src="/images/philosophy-badge.png"
                  title="Philosophy badge.png"
                  alt="Philosophy badge.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="my-40 flex flex-wrap" id="partner">
          <div className="mb-24 flex w-full flex-col items-center gap-32 lg:flex-row">
            <div className="w-full">
              <article>
                <div className="mb-24">
                  <p>FEATURED PARTNER</p>
                </div>
                <Typography className="mb-24" fontSize={36}>
                  Loomstate
                </Typography>
                <p className="mb-24 text-left xl:text-justify">
                  As a pioneer in sustainable fashion, Loomstate was a natural fit to become our exclusive restaurant
                  uniform partner. They play an integral role in our shared vision of thoughtfully-designed apparel made
                  with certified organic materials and socially-responsible methods of production.
                </p>
              </article>
            </div>
            <div className="w-full">
              <img
                className="img-responsive w-full"
                src="/images/partner-top.png"
                title="partner-top.png"
                alt="partner-top.png"
              />
            </div>
          </div>
          <div className="w-full">
            <img
              className="img-responsive w-full"
              src="/images/partner-center.png"
              title="partner-center.png"
              alt="partner-center.png"
            />
          </div>
          <div className="my-40">
            <div className="flex flex-col lg:flex-row lg:gap-16">
              <div className="mb-24">
                <Typography fontSize={18}>Organic Cotton Looks Better on Everyone</Typography>
              </div>
              <article className="w-full">
                <p className="text-left xl:text-justify">
                  Organic farmers avoid the use of petroleum-based chemical pesticides and fertilizers, instead relying
                  on natural methods and a deep connection to their land to yield a healthy crop. This allows water to
                  stay clean and free of chemicals, allows soil to naturally retain necessary nutrients and moisture,
                  and provides a healthy working environment for growers and their families.
                </p>
              </article>
            </div>
          </div>

          <div className="partner-carousel hidden w-full">
            <div>
              <div>
                <div
                  id="r9_r1_r13_r1"
                  className="carousel slide"
                  data-ride="carousel"
                  data-interval="3000"
                  data-pause="hover"
                  data-wrap="true"
                >
                  <div className="carousel-inner">
                    <div className="item">
                      <div className="w-1/3" data-slide="prev" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-1.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3">
                        <img src="/images/carousel-2.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3" data-slide="next" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-3.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                    </div>
                    <div className="item active">
                      <div className="w-1/3" data-slide="prev" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-2.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3">
                        <img src="/images/carousel-3.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3" data-slide="next" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-1.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="w-1/3" data-slide="prev" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-3.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-1.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                      <div className="w-1/3" data-slide="next" data-target="#r9_r1_r13_r1">
                        <img src="/images/carousel-2.png" alt="" className="img-responsive" />
                        <div className="carousel-text"></div>
                      </div>
                    </div>
                  </div>
                  <ol className="carousel-indicators">
                    <li data-target="#r9_r1_r13_r1" data-slide-to="0" className=""></li>
                    <li data-target="#r9_r1_r13_r1" data-slide-to="1" className="active"></li>
                    <li data-target="#r9_r1_r13_r1" data-slide-to="2" className=""></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-24 flex w-full flex-col items-center gap-32 lg:flex-row">
            <div className="w-full">
              <img
                className="img-responsive w-full"
                src="/images/partner-center-2.png"
                title="partner-center-2.png"
                alt="partner-center-2.png"
              />
            </div>

            <div className="w-full">
              <article>
                <Typography className="mb-24" fontSize={18}>
                  Committed to Communities
                </Typography>
                <p className="text-left xl:text-justify">
                  Loomstate believes our farmers, thread makers, fabric knitters, printers, cutters and sewers are our
                  community. They’re helping to lead and organize groundbreaking collaborative supply chain
                  partnerships, like organizing small and marginal land holders into cooperatives to ensure the best
                  market access and prices through the Chetna Coalition, for truly sustainable clothing and textile
                  production from farm to shelf.
                </p>
              </article>
            </div>
          </div>
          <div className="w-full">
            <img
              className="img-responsive w-full"
              src="/images/partner-bottom.png"
              title="partner-bottom.png"
              alt="partner-bottom.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

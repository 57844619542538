import React, { FC, useEffect, useMemo, useState } from 'react';
import NextLink from 'next/link';
import { Product } from '@commercetools/frontend-domain-types/product/Product';
import GreenLandScore, { IconText } from 'components/commercetools-ui/organisms/greenland-score';
// TODO: implement a better design for quickview
import Prices from 'components/commercetools-ui/organisms/product/product-tile/prices';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useVariantWithDiscount from 'helpers/hooks/useVariantWithDiscount';
import { desktop } from 'helpers/utils/screensizes';
import { Variant } from 'types/product';
import useImpacts from 'frontastic/hooks/useImpacts';
import Image from 'frontastic/lib/image';
import useTrack from './useTrack';
interface ProductTileProps {
  product: Product;
  onClick?: () => void;
  isSearchResult?: boolean;
  disableQuickView?: boolean;
  disableWishlistButton?: boolean;
  disableVariants?: boolean;
  style?: object;
}

const ProductTile: FC<ProductTileProps> = ({
  product,
  onClick,
  isSearchResult = false,
  disableQuickView = false,
  disableWishlistButton = false,
  disableVariants = false,
  style,
}) => {
  const [isDesktopSize] = useMediaQuery(desktop);

  const { ref } = useTrack({ product });
  const { getImpactByProduct } = useImpacts();
  const [iconsData, setIconData] = useState<IconText[]>([{ icon: <></>, text: '', units: '' }]);

  const variantWithDiscount = useVariantWithDiscount(product.variants) as Variant;

  const discountedPrice = useMemo(() => variantWithDiscount?.discountedPrice, [variantWithDiscount]);

  const discountPercentage = useMemo(
    () =>
      variantWithDiscount
        ? (((variantWithDiscount.price?.centAmount as number) - (discountedPrice?.centAmount as number)) /
            (variantWithDiscount.price?.centAmount as number)) *
          100
        : 0,
    [discountedPrice, variantWithDiscount],
  );

  const [selectedVariant, setSelectedVariant] = useState(() => variantWithDiscount ?? product?.variants[0]);

  const productUrl = useMemo(() => {
    if (!product._url) return '#';

    if (isSearchResult) return `${product._url}?sr=1`;
    return product._url;
  }, [product._url, isSearchResult]);

  useEffect(() => {
    setIconData(getImpactByProduct(product.variants[0]));
  }, [product.variants]);

  return (
    <div onClick={onClick} ref={ref} className="rounded-md border p-10" style={style}>
      <div className="relative">
        <NextLink href={productUrl}>
          <a>
            <div className="relative w-full">
              <div className="relative bg-white p-8 md:p-16">
                <div className="relative block w-full" style={{ paddingBottom: '122%' }}>
                  <Image
                    src={selectedVariant.images?.[0]}
                    suffix="zoom"
                    alt={product.name}
                    objectFit="contain"
                    objectPosition="center"
                    className="w-full rounded-sm group-hover:opacity-75 md:p-16"
                  />
                </div>
              </div>
              <span
                className="absolute right-0 top-0 z-10 flex cursor-pointer items-center justify-center"
                onClick={(e) => e.preventDefault()}
              >
                {/* <GreenLandScore iconsWithText={iconsData} /> removed while we create a setting to manage if the environmental impacts are shown in cards */}
              </span>
            </div>
          </a>
        </NextLink>
      </div>

      <NextLink href={productUrl}>
        <a>
          <div>
            <div
              className={`mt-4 block max-w-[80%] overflow-hidden text-ellipsis whitespace-pre pb-6 font-BarlowSemiCondensedRegular text-lg leading-none text-black md:mt-12`}
            >
              {product?.name}
            </div>
            <div>
              <Prices
                price={variantWithDiscount?.price ?? selectedVariant?.price}
                discountedPrice={variantWithDiscount?.discountedPrice}
              />
            </div>
          </div>
        </a>
      </NextLink>
    </div>
  );
};

export default ProductTile;
